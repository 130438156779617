export const data = [
  {
    week: "1 нед",
    value: 10,
    type: "Встреча",
  },
  {
    week: "1 нед",
    value: 4,
    type: "Контракт",
  },
  {
    week: "1 нед",
    value: 3,
    type: "Отказ",
  },
  {
    week: "2 нед",
    value: 3,
    type: "Встреча",
  },
  {
    week: "2 нед",
    value: 10,
    type: "Контракт",
  },
  {
    week: "2 нед",
    value: 7,
    type: "Отказ",
  },
  {
    week: "3 нед",
    value: 1,
    type: "Встреча",
  },
  {
    week: "3 нед",
    value: 3,
    type: "Контракт",
  },
  {
    week: "3 нед",
    value: 2,
    type: "Отказ",
  },
  {
    week: "4 нед",
    value: 0,
    type: "Встреча",
  },
  {
    week: "4 нед",
    value: 1,
    type: "Контракт",
  },
  {
    week: "4 нед",
    value: 0,
    type: "Отказ",
  },
];
