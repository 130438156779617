export const carData = [{ title: "Toyota Camry, B5, 089/2", price: 17000000 }];

export const additionalData = [
  { title: "Автосигнализация Starline A93", price: 300000 },
  { title: "Шины", price: 200000 },
  { title: "Тонировка", price: 50000 },
];

export const creditColumns = [
  {
    title: "Период",
    dataIndex: "period",
    key: "period",
  },
  {
    title: "Ежемесячный платеж",
    dataIndex: "monthly",
    key: "monthly",
  },
  {
    title: "Переплата",
    dataIndex: "overpayment",
    key: "overpayment",
  },
];
export const creditData = new Array(9).fill(1).map((_, index) => ({
  key: index,
  period: (index + 2) * 6,
  monthly: 10000,
  overpayment: 1000000,
}));
