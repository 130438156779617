import React from "react";
import "./index.less";

export default function FloatLabel(props) {
  const { id, children, label, value: values, type, onChange } = props;
  const [focus, setFocus] = React.useState(true);

  const labelClassName = focus ? "label label__float" : "label";

  const onInputChange = (e) => {
    if (type === "input") onChange({ [id]: e.target.value });
    else onChange({ [id]: e });
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      onChange: onInputChange,
      value: values && values[id] ? values[id] : null,
    });
  });

  return (
    <div
      className="float__label"
      onFocus={() => setFocus(true)}
      // onBlur={() => setFocus(false)}
    >
      {childrenWithProps}
      <label className={labelClassName}>{label}</label>
    </div>
  );
}
