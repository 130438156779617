import React from "react";
import { Column } from "@ant-design/charts";
import { data } from "./data";

const Chart = () => {
  var config = {
    data: data,
    height: 150,
    isStack: true,
    xField: "week",
    yField: "value",
    seriesField: "type",
    columnBackground: { style: { fill: "rgba(0,0,0,0.1)" } },
  };
  return <Column {...config} />;
};
export default Chart;
