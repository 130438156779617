import { Tag, Button } from "antd";

export const columns = [
  {
    title: "Автомобиль",
    dataIndex: "car",
    key: "car",
  },
  {
    title: "Комплектация",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    render: (val) => {
      if (val.id === 1) return <Tag color="green">{val.name}</Tag>;
      else if (val.id === 2) return <Tag color="red">{val.name}</Tag>;
      return <Tag>{val.name}</Tag>;
    },
  },
  {
    title: "Расписание",
    dataIndex: "schedule",
    key: "schedule",
    render: () => (
      <Button type="link" size="small">
        Посмотреть
      </Button>
    ),
  },
  {
    title: "Время брони",
    dataIndex: "reserve_time",
    key: "reserve_time",
  },
];

export const data = [
  {
    key: "1",
    car: "Toyota Camry",
    type: "B5",
    status: {
      id: 1,
      name: "Свободно",
    },
  },
  {
    key: "2",
    car: "Toyota Rav4",
    type: "85",
    status: {
      id: 2,
      name: "Занято",
    },
  },
  {
    key: "3",
    car: "Toyota Land Cruiser",
    type: "NH",
    status: {
      id: 1,
      name: "Свободно",
    },
  },
];
