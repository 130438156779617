import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { Layout } from "antd";
// import { userIsAuthenticatedRedir, userIsNotAuthenticatedRedir } from "./auth";
// import { userIsAuthenticated } from "./auth";

// import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import Warehouse from "./screens/Warehouse";
import Task from "./screens/Task";
import NotFound from "./screens/NotFound";

import { Sider } from "./ui";

import "antd/dist/antd.less";
import "./App.less";

// const LoginView = userIsNotAuthenticatedRedir(Login);
// const DashboardView = userIsAuthenticatedRedir(Dashboard);
// const TaskView = userIsAuthenticatedRedir(Task);

const App = () => (
  <BrowserRouter>
    <Layout className="main__wrapper">
      <Sider />
      <Layout>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/warehouse" component={Warehouse} />
          <Route exact path="/task/:id" component={Task} />
          {/* <Route path="/login" component={LoginView} /> */}
          <Route path="*" component={NotFound} />
        </Switch>
      </Layout>
    </Layout>
  </BrowserRouter>
);

export default App;
