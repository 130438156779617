import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const Description = ({ children }) => (
  <div className="margin__bottom">
    <Text>{children}</Text>
  </div>
);

export default Description;
