import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { Layout, Menu, Popover } from "antd";
import { HomeOutlined, ShopOutlined } from "@ant-design/icons";
// import { LOGOUT } from "@/actions/auth";
import "./index.less";

const { Sider } = Layout;

const Comp = (props) => {
  // const token = useSelector((state) => state.auth.token);
  // let token;
  const { pathname } = props.location;

  const hideHeader = ["/login"];

  // const dispatch = useDispatch();
  // const logout = () => {
  //   dispatch(LOGOUT());
  // };

  if (hideHeader.indexOf(pathname) !== -1) return <div />;
  // else if (!token) return <div />;

  let selectedKeys = "/" + pathname.split("/")[1];

  if (selectedKeys === "/task") selectedKeys = "/";

  return (
    <Sider theme="light" trigger={null} collapsed={true} collapsible>
      <div className="logo" />
      <Menu selectedKeys={selectedKeys}>
        <Menu.Item key="/" title={null}>
          <Popover content="Дашборд" trigger="hover" placement="right">
            <NavLink to="/">
              <HomeOutlined />
            </NavLink>
          </Popover>
        </Menu.Item>
        <Menu.Item key="/warehouse" title={null}>
          <Popover content="Склад" trigger="hover" placement="right">
            <NavLink to="/warehouse">
              <ShopOutlined />
            </NavLink>
          </Popover>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default withRouter(Comp);
