import React from "react";
import moment from "moment";
import { Button, Row, Col, Typography } from "antd";
import { List, Tag } from "antd";
import { data } from "./data";
import "./index.less";

const { Text } = Typography;

const Meetings = () => {
  const [active, setActive] = React.useState(moment());

  return (
    <>
      <DayPicker active={active} setActive={setActive} />
      <div className="meetings__description">
        <Text type="secondary">
          На сегодня запланировано встреч: <Text strong>{data.length}</Text>
        </Text>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <div key={item.key}>
            <List.Item className="meeting__card">
              <List.Item.Meta
                title={item.title}
                description={item.description}
              />
              <div>
                <Tag color="blue">{item.date}</Tag>
              </div>
            </List.Item>
          </div>
        )}
      />
    </>
  );
};

const DayPicker = (props) => {
  const { active, setActive } = props;

  const currentDate = moment();
  const weekStart = currentDate.clone().startOf("isoWeek");

  let days = [];

  for (let i = 0; i <= 6; i++) {
    const day = moment(weekStart).add(i, "days");
    days.push(day);
  }

  const setDate = (date) => {
    setActive(date);
  };

  return (
    <Row>
      {days.map((d) => {
        const key = d.format("DD");
        const today = currentDate.isSame(d, "day");

        const buttonClassName = today ? "date__today" : "";

        const activeType = active.isSame(d, "day") ? "primary" : "default";

        return (
          <Col flex="auto" className="date__container" key={key}>
            <div>{d.format("ddd")}</div>
            <Button
              type={activeType}
              onClick={() => setDate(d)}
              className={buttonClassName}
              shape="circle"
            >
              {key}
            </Button>
          </Col>
        );
      })}
    </Row>
  );
};

export default Meetings;
