import React from "react";
import _ from "lodash";
import { Description } from "@/components";
import { ClientForm } from "@/modules";
import { Timeline, Card, Button, Divider } from "antd";
import { Table, Col, Upload, Radio } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { columns, data } from "./data";
import { Context } from "../";
import "./index.less";

const Request = () => {
  const context = React.useContext(Context);
  const { setNext } = context;
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [step, setStep] = React.useState(0);
  const [isTestDrive, setIsTestDrive] = React.useState(null);
  const [onTimeTestDrive, setOnTimeTestDrive] = React.useState(null);

  const timer = React.useRef();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setNext({ disabled: true });
    if (selectedRowKeys.length === 0 && step === 2) setStep(1);
    else if (selectedRowKeys.length !== 0 && onTimeTestDrive === "1")
      setStep(2);
  }, [selectedRowKeys, setStep, setNext]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (step > 3) setNext({ disabled: false });
  }, [step, setNext]);

  const onFieldsChange = (__, fields) => {
    const _fields = _.filter(fields, (field) => {
      const { name } = field;
      const [key] = name;
      return field.value && !!field.value[key];
    });
    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      if (_fields.length === fields.length) setStep(1);
      else {
        setStep(0);
        setSelectedRowKeys([]);
      }
    }, 500);
  };

  const onQuestion = (name) => (e) => {
    if (name === "test_drive") {
      setIsTestDrive(e.target.value);
      if (e.target.value === "0") setNext({ disabled: false });
      else setNext({ disabled: true });
    } else if (name === "on_time") {
      setOnTimeTestDrive(e.target.value);
      setSelectedRowKeys([]);
      if (e.target.value === "1") setStep(0);
      else setStep(1);
    }
  };

  return (
    <Col span={24}>
      <Card bordered={false} className="card__main">
        <Description>
          Клиент желает провести тест-драйв?
          <Radio.Group
            options={[
              { label: "Да", value: "1" },
              { label: "Нет", value: "0" },
            ]}
            onChange={onQuestion("test_drive")}
            value={isTestDrive}
            style={{ marginLeft: 15 }}
            optionType="button"
            buttonStyle="solid"
          />
        </Description>
        {isTestDrive === "1" && (
          <Description>
            Клиент находится в автосалоне?
            <Radio.Group
              options={[
                { label: "Да", value: "1" },
                { label: "Нет", value: "0" },
              ]}
              onChange={onQuestion("on_time")}
              value={onTimeTestDrive}
              style={{ marginLeft: 15 }}
              optionType="button"
              buttonStyle="solid"
            />
          </Description>
        )}
        {isTestDrive === "1" && !_.isNull(onTimeTestDrive) && (
          <>
            <Divider />
            <Timeline>
              {onTimeTestDrive === "1" && (
                <Timeline.Item>
                  <Description>Заполните данные клиента</Description>
                  <Col span={16}>
                    <ClientForm onFieldsChange={onFieldsChange} />
                  </Col>
                </Timeline.Item>
              )}
              <Timeline.Item>
                {onTimeTestDrive === "1" ? (
                  <Description>Выберите авто для тест-драйва</Description>
                ) : (
                  <Description>
                    Выберите авто для бронирования тест-драйва
                  </Description>
                )}
                <Col span={16}>
                  <Table
                    className="tasks__table"
                    size="small"
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    scroll={{ y: 150 }}
                  />
                </Col>
              </Timeline.Item>
              {onTimeTestDrive === "1" && (
                <>
                  <Timeline.Item>
                    <Description>
                      Распечатайте и подпишите документы
                    </Description>
                    <Button
                      onClick={() => {
                        const url =
                          "https://mag.wcoomd.org/uploads/2018/05/blank.pdf";
                        window.open(url, "_blank");
                        setStep(3);
                      }}
                      disabled={step < 2}
                      type="primary"
                    >
                      Печать
                    </Button>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Description>
                      Загрузите подписанный и отсканированный документ
                    </Description>
                    <Upload onChange={() => setStep(4)}>
                      <Button disabled={step < 3} type="primary">
                        <UploadOutlined />
                        Выбрать файлы
                      </Button>
                    </Upload>
                  </Timeline.Item>
                </>
              )}
            </Timeline>
          </>
        )}
      </Card>
    </Col>
  );
};

export default Request;
