import moment from "moment";
import { Typography, Tag } from "antd";

const { Text } = Typography;

export const columns = [
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
    render: (val) => {
      const date = moment(val);
      if (!date.isValid()) return null;
      return date.format("DD.MM.YYYY HH:mm");
    },
  },
  {
    title: "Назначение",
    dataIndex: "target",
    key: "target",
  },
  {
    title: "Менеджер",
    dataIndex: "manager",
    key: "manager",
  },
  {
    title: "Автосалон",
    dataIndex: "place",
    key: "place",
  },
  {
    title: "Этап",
    dataIndex: "stage",
    key: "stage",
    render: (val) => {
      if (val.id === 1) return <Tag color="gold">{val.name}</Tag>;
      else if (val.id === 2) return <Tag color="green">{val.name}</Tag>;
      else if (val.id === 3) return <Tag color="cyan">{val.name}</Tag>;
      return <Tag>{val.name}</Tag>;
    },
  },
];

export const data = [
  {
    key: "1",
    date: "2021-05-07T12:00:10Z",
    manager: "Иванов Петр",
    place: "Toyota City Almaty",
    target: "Toyota Camry",
    stage: {
      id: 1,
      name: "Заявка",
    },
  },
  {
    key: "2",
    date: "2021-05-21T14:00:10Z",
    manager: "Баратов Серік",
    place: "Porsche Center Almaty",
    target: "Porsche 911",
    stage: {
      id: 2,
      name: "Контракт",
    },
  },
  {
    key: "3",
    date: "2021-06-16T12:00:10Z",
    manager: "Молдабаева Айнур",
    place: "Toyota City Almaty",
    target: "Toyota Land Cruiser",
    stage: {
      id: 3,
      name: "Выдача",
    },
  },
  {
    key: "4",
    date: "2021-08-01T14:43:10Z",
    manager: "Климов Андрей",
    place: "Porsche Center Almaty",
    target: "Porsche Panamera",
    stage: {
      id: 1,
      name: "Заявка",
    },
  },
];

export const carColumns = [
  {
    title: "Автомобиль",
    dataIndex: "car",
    key: "car",
  },
  {
    title: "Комплектация",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Цвет",
    dataIndex: "color",
    key: "color",
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    render: (val) => {
      if (val.id === 1) return <Tag color="gold">{val.name}</Tag>;
      else if (val.id === 2) return <Tag color="green">{val.name}</Tag>;
      else if (val.id === 3) return <Tag color="cyan">{val.name}</Tag>;
      return <Tag>{val.name}</Tag>;
    },
  },
  {
    title: "Дата",
    dataIndex: "inStockDate",
    key: "inStockDate",
    render: (val) => {
      const date = moment(val);
      return <Text>{date.format("DD.MM.YYYY")}</Text>;
    },
  },
  {
    title: "Клиент",
    dataIndex: "client",
    key: "client",
  },
  {
    title: "Менеджер",
    dataIndex: "manager",
    key: "manager",
  },
  {
    title: "Резерв до",
    dataIndex: "reserveDate",
    key: "reserveDate",
    render: (val) => {
      const date = moment(val);
      if (!date.isValid()) return null;
      return <Text>{date.format("DD.MM.YYYY")}</Text>;
    },
  },
  {
    title: "Стоимость",
    dataIndex: "price",
    key: "price",
  },
];

const item = {
  car: "Toyota Camry",
  type: "B5",
  color: "089/22",
  inStockDate: "2021-08-01T14:43:10Z",
  client: "Петров Николай",
  manager: "Климов Андрей",
  reserveDate: null,
  price: "17 000 000",
  status: {
    id: 1,
    name: "В пути",
  },
};

export const carData = new Array(10)
  .fill(1)
  .map((v, i) => ({ ...item, key: i.toString() }));
