import React from "react";
import "./index.less";

const Screen = (props) => {
  const { history } = props;
  React.useEffect(() => {
    history.push("/");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return <div />;
};

export default Screen;
