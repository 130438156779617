import Immutable from "immutable";
// import _ from "lodash";

const INITIAL_STATE = {
  isLoading: false,
  token: null,
  message: null,
};

const reducer = (state = INITIAL_STATE, data) => {
  var newState;
  switch (data.type) {
    case "AUTH":
      if (data.action === "REQUEST") {
        return { ...state, isLoading: true };
      } else if (data.action === "REFRESH") {
        return { ...state, isLoading: false };
      } else if (data.action === "SUCCESS") {
        var params = {
          ...state,
          ...data.data,
          isLoading: false,
        };
        return params;
      } else {
        return { ...state, message: data.message, isLoading: false };
      }
    case "LOGOUT":
      return { ...INITIAL_STATE };
    case "SET_AUTH":
      newState = Immutable.fromJS(state).setIn(data.path, data.value);
      return newState.toJS();
    default:
      return state;
  }
};

export default reducer;
