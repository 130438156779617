import React from "react";
import { columns, data, carColumns, carData } from "./data";
import FloatLabel from "@/components/FloatLabel";
import { Input, Form, Tag, Card, Button } from "antd";
import { Select, Table, Col } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { Context } from "../";

const { Option } = Select;

const Request = () => {
  const context = React.useContext(Context);
  const { setNext, setPrev } = context;
  const [saving, setSaving] = React.useState(false);

  const form = React.useRef();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setPrev({ disabled: false });
  }, [setPrev]);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const onFieldsChange = (fields) => {
    if (fields.length > 0) {
      const [field] = fields;
      const [name] = field.name;
      if (name === "name") {
        setSaving(true);
        setTimeout(() => {
          setSaving(false);
        }, 1000);
      }
    }
  };

  return (
    <>
      <Col span={6}>
        <Card bordered={false} style={{ height: 290 }} className="card__main">
          <Form
            ref={form}
            onFieldsChange={onFieldsChange}
            style={{ textAlign: "center" }}
          >
            <Form.Item>
              <Tag color="processing">Встреча</Tag>
            </Form.Item>
            <Form.Item>
              <Button size="small" type="link">
                <span style={{ marginRight: 8 }}>
                  <PhoneOutlined /> +7(701) 123 53 53
                </span>
              </Button>
            </Form.Item>
            <Form.Item
              name="name"
              validateStatus="validating"
              hasFeedback={saving}
            >
              <FloatLabel type="input" label="Имя">
                <Input placeholder="Введите текст" />
              </FloatLabel>
            </Form.Item>
            <Button type="primary">Посмотреть карточку</Button>
          </Form>
        </Card>
      </Col>
      <Col span={18}>
        <Card bordered={false} title="История клиента" className="card__main">
          <Table
            className="tasks__table"
            size="small"
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ y: 150 }}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card bordered={false} title="Потребность" className="card__main">
          <Form layout="inline" style={{ marginBottom: 12 }}>
            <Form.Item name="model">
              <FloatLabel type="select" label="Модель">
                <Select style={{ width: 150 }}>
                  {["Camry", "Corolla", "Rav 4"].map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </FloatLabel>
            </Form.Item>
            <Form.Item name="color">
              <FloatLabel type="select" label="Цвет">
                <Select style={{ width: 150 }}>
                  {["089/22"].map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </FloatLabel>
            </Form.Item>
            <Form.Item name="status">
              <FloatLabel type="select" label="Статус">
                <Select style={{ width: 150 }}>
                  {["В наличии", "На заказ", "В пути"].map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </FloatLabel>
            </Form.Item>
            <Form.Item>
              <Button type="primary">Поиск</Button>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  const url =
                    "https://mag.wcoomd.org/uploads/2018/05/blank.pdf";
                  window.open(url, "_blank");
                }}
                type="primary"
                disabled={selectedRowKeys.length === 0}
              >
                Сформировать КП
              </Button>
            </Form.Item>
          </Form>
          <Table
            className="tasks__table"
            size="small"
            columns={carColumns}
            dataSource={carData}
            pagination={false}
            scroll={{ y: 240 }}
            onRow={(record, index) => {
              return {
                onClick: (e) => {
                  setSelectedRowKeys([record.key]);
                  setNext({ disabled: false });
                },
              };
            }}
            rowSelection={{
              type: "radio",
              onChange: (selectedRows) => {
                setSelectedRowKeys(selectedRows);
                setNext({ disabled: false });
              },
              selectedRowKeys,
            }}
          />
        </Card>
      </Col>
    </>
  );
};

export default Request;
