import React from "react";
import { Col, Row, Card, Table, Dropdown } from "antd";
import { Grid, Layout, Button } from "antd";
import { columns, data } from "./data";
import { extraColumns, extraData, extraTasksColumns } from "./data";
import { PlusOutlined } from "@ant-design/icons";
import Chart from "./Chart";
import Meetings from "./Meetings";
import "./index.less";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const Screen = (props) => {
  const { history } = props;

  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

  const screens = useBreakpoint();

  let span = { left: 16, right: 8 };
  if (screens.xxl) span = { left: 18, right: 6 };

  return (
    <Content className="main__content__layout">
      <Row gutter={20}>
        <Col span={span.left}>
          <Card
            bordered={false}
            title="Мои задачи"
            className="card__main"
            extra={[
              <Button key="add" shape="round" type="primary">
                <PlusOutlined />
                Новая задача
              </Button>,
            ]}
          >
            <Table
              className="tasks__table"
              size="small"
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{ y: 150 }}
              onRow={(record) => ({
                onClick: (e) => history.push("/task/" + record.key),
              })}
            />
          </Card>
          <Card
            bordered={false}
            title="На других отделах"
            className="card__main"
          >
            <Table
              className="tasks__table"
              size="small"
              columns={extraColumns}
              dataSource={extraData}
              pagination={false}
              scroll={{ y: 150 }}
              expandedRowKeys={expandedRowKeys}
              onExpand={(expanded, record) => {
                if (!expanded) setExpandedRowKeys([]);
                else setExpandedRowKeys([record.key]);
              }}
              expandable={{
                expandedRowRender: (record) => {
                  return (
                    <Table
                      className="tasks__table"
                      size="small"
                      columns={extraTasksColumns}
                      dataSource={record.tasks}
                      pagination={false}
                      onRow={(record) => ({
                        onClick: (e) => history.push("/task/" + record.key),
                      })}
                    />
                  );
                },
              }}
              onRow={(record) => ({
                onClick: (e) => {
                  if (expandedRowKeys.indexOf(record.key) === -1)
                    setExpandedRowKeys([record.key]);
                  else setExpandedRowKeys([]);
                },
              })}
            />
          </Card>
        </Col>
        <Col span={span.right}>
          <Card bordered={false} title="Статистика" className="card__main">
            <Chart />
          </Card>
          <Card
            bordered={false}
            title="Мои встречи"
            className="card__main"
            extra={<MeetingsExtra />}
          >
            <Meetings />
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

const MeetingsExtra = () => {
  return (
    <a href=":" onClick={(e) => e.preventDefault()}>
      Все встречи
    </a>
  );
};

export default Screen;
