import moment from "moment";
import { Tag, Button } from "antd";

export const columns = [
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
    render: (val) => {
      const date = moment(val);
      if (!date.isValid()) return null;
      return date.format("DD.MM.YYYY HH:mm");
    },
  },
  {
    title: "Клиент",
    dataIndex: "client",
    key: "client",
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Назначение",
    dataIndex: "target",
    key: "target",
  },
  {
    title: "Этап",
    dataIndex: "stage",
    key: "stage",
    render: (val) => {
      if (val.id === 1) return <Tag color="gold">{val.name}</Tag>;
      else if (val.id === 2) return <Tag color="green">{val.name}</Tag>;
      else if (val.id === 3) return <Tag color="blue">{val.name}</Tag>;
      return <Tag>{val.name}</Tag>;
    },
  },
];

export const data = [
  {
    key: "1",
    date: "2021-05-07T12:00:10Z",
    client: "Иванов Петр",
    phone: "+7 (777) 432 11 23",
    target: "Toyota Camry",
    stage: {
      id: 1,
      name: "Заявка",
    },
  },
  {
    key: "2",
    date: "2021-05-21T14:00:10Z",
    client: "Баратов Серік",
    phone: "+7 (701) 034 76 01",
    target: "Toyota Land Cruiser",
    stage: {
      id: 2,
      name: "Контракт",
    },
  },
  {
    key: "3",
    date: "2021-06-16T12:00:10Z",
    client: "Молдабаева Айнур",
    phone: "+7 (702) 345 55 13",
    target: "Toyota Land Cruiser",
    stage: {
      id: 3,
      name: "Выдача",
    },
  },
  {
    key: "4",
    date: "2021-08-01T14:43:10Z",
    client: "Климов Андрей",
    phone: "+7 (702) 415 22 11",
    target: "Toyota Rav4",
    stage: {
      id: 1,
      name: "Заявка",
    },
  },
];

export const extraColumns = [
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
    width: 150,
    render: (val) => {
      const date = moment(val);
      if (!date.isValid()) return null;
      return date.format("DD.MM.YYYY HH:mm");
    },
  },
  {
    title: "Клиент",
    dataIndex: "client",
    key: "client",
    width: 205,
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
  },
];

export const extraTasksColumns = [
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
    width: 165,
    render: (val) => {
      const date = moment(val);
      if (!date.isValid()) return null;
      return date.format("DD.MM.YYYY HH:mm");
    },
  },
  {
    title: "Отдел",
    dataIndex: "group",
    key: "group",
    width: 205,
  },
  {
    title: "Этап",
    dataIndex: "stage",
    key: "stage",
    render: (val) => {
      if (val.id === 1) return <Tag color="gold">{val.name}</Tag>;
      else if (val.id === 2) return <Tag color="green">{val.name}</Tag>;
      else if (val.id === 3) return <Tag color="blue">{val.name}</Tag>;
      return <Tag>{val.name}</Tag>;
    },
  },
];

export const extraData = [
  {
    key: "1",
    date: "2021-05-08T13:20:10Z",
    client: "Сейсембаев Маргулан",
    phone: "+7 (777) 345 11 23",
    tasks: [
      {
        key: "1",
        date: "2021-05-08T13:20:10Z",
        group: "Trade-in",
        stage: {
          id: 1,
          name: "Заявка",
        },
      },
      {
        key: "2",
        date: "2021-05-08T13:20:10Z",
        group: "Кредит",
        stage: {
          id: 2,
          name: "Одобрение",
        },
      },
    ],
  },
];
