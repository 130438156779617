export const data = [
  {
    key: "1",
    title: "Продажа автомомибиля",
    description: "Встреча с Клиентом Дмитрий",
    date: "10:00",
  },
  {
    key: "2",
    title: "Продажа автомомибиля",
    description: "Встреча с Клиентом Арсен",
    date: "14:00",
  },
  {
    key: "3",
    title: "Продажа автомомибиля",
    description: "Встреча с Клиентом Ақмарал",
    date: "16:00",
  },
];
