import React from "react";
import { Row, Col, Steps, Button } from "antd";
import { Modal, Layout } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { LeftOutlined } from "@ant-design/icons";
import Request from "./Request";
import TestDrive from "./TestDrive";
import PreSale from "./PreSale";
import "./index.less";

const { Content } = Layout;
const { Step } = Steps;
const { confirm } = Modal;

export const Context = React.createContext();

const steps = ["Заявка", "Тест-драйв", "Предпродажа", "Контракт", "Выдача"];

const Screen = (props) => {
  const { history, match } = props;
  const [active, setActive] = React.useState(0);
  const [next, setNext] = React.useState({ disabled: true });
  const [prev, setPrev] = React.useState({ disabled: true });

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setPrev({ disabled: true });
    if (!match.params.id) history.push("/");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onReject = () => {
    confirm({
      title: "Изменение статуса задачи",
      icon: <ExclamationCircleOutlined />,
      content: 'Вы уверены, что хотите изменить статус в "Отказ"',
      onOk() {
        history.push("/");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onBack = () => {
    history.push("/");
  };

  return (
    <Context.Provider value={{ setPrev, setNext, setActive }}>
      <Row className="top__panel">
        <Col span={4}>
          <Button onClick={onBack} icon={<LeftOutlined />} type="link">
            На главную
          </Button>
        </Col>
        <Col span={16}>
          <Steps size="small" current={active}>
            {steps.map((item, index) => (
              <Step key={index} title={item} />
            ))}
          </Steps>
        </Col>
      </Row>
      <Content style={{ paddingTop: 70 }} className="main__content__layout">
        <Row gutter={20} className="asdasd">
          {active === 0 && <Request />}
          {active === 1 && <TestDrive />}
          {active === 2 && <PreSale />}
        </Row>
      </Content>

      <div className="bottom__panel">
        <div className="left__panel">
          <Button {...prev} onClick={() => setActive(active - 1)}>
            Назад
          </Button>
        </div>
        <div className="right__panel">
          <Button danger ghost onClick={onReject}>
            Отказ
          </Button>
          {active === 0 && (
            <Button type="primary" ghost>
              Перевод в другой отдел
            </Button>
          )}
          {active < steps.length - 1 && (
            <Button
              {...next}
              onClick={() => setActive(active + 1)}
              type="primary"
            >
              Далее
            </Button>
          )}
          {active === steps.length - 1 && (
            <Button type="primary">Завершить</Button>
          )}
        </div>
      </div>
    </Context.Provider>
  );
};

export default Screen;
