import React from "react";
import { Form, Col, Row, Button, Input } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { FloatLabel, MaskedInput } from "@/components";

const ClientForm = (props) => {
  const { onFieldsChange, isPhone } = props;
  const form = React.useRef();

  return (
    <Form ref={form} onFieldsChange={onFieldsChange} className="inline__form">
      <Row gutter={30}>
        {isPhone ? (
          <Col span={6}>
            <Form.Item name="phone">
              <FloatLabel type="input" label="Телефон">
                <MaskedInput mask="+7 111 111 11 11" />
              </FloatLabel>
            </Form.Item>
          </Col>
        ) : (
          <Col span={6}>
            <Form.Item>
              <Button size="small" type="link" className="phone__number">
                <span style={{ marginRight: 8 }}>
                  <PhoneOutlined /> +7(701) 123 53 53
                </span>
              </Button>
            </Form.Item>
          </Col>
        )}
        <Col span={6}>
          <Form.Item name="name">
            <FloatLabel type="input" label="Имя">
              <Input placeholder="Введите текст" />
            </FloatLabel>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="last_name">
            <FloatLabel type="input" label="Фамилия">
              <Input placeholder="Введите текст" />
            </FloatLabel>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="middle_name">
            <FloatLabel type="input" label="Отчество">
              <Input placeholder="Введите текст" />
            </FloatLabel>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={6}>
          <Form.Item name="client_id">
            <FloatLabel type="input" label="ИИН">
              <Input placeholder="Введите текст" />
            </FloatLabel>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="document_id">
            <FloatLabel type="input" label="Номер удостоверения">
              <Input placeholder="Введите текст" />
            </FloatLabel>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="document_by">
            <FloatLabel type="input" label="Кем выдан">
              <Input placeholder="Введите текст" />
            </FloatLabel>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="document_date">
            <FloatLabel type="input" label="Когда выдан">
              <Input placeholder="Введите текст" />
            </FloatLabel>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

ClientForm.defaultProps = {
  isPhone: false,
};

export default ClientForm;
