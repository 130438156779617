import React from "react";
import _ from "lodash";
import NumberFormat from "react-number-format";
import { ClientForm } from "@/modules";
import { Description } from "@/components";
import { List, Timeline, Card, Button, Table } from "antd";
import { Col, Divider, Radio } from "antd";
import { Form, InputNumber } from "antd";
import { FloatLabel } from "@/components";
import { Context } from "../";
import { carData, additionalData } from "./data";
import { creditData, creditColumns } from "./data";
import "./index.less";

const Request = () => {
  const context = React.useContext(Context);
  const { setNext, setPrev } = context;
  const [sameClient, setSameClient] = React.useState("1");
  const [credit, setCredit] = React.useState(null);
  const [tradein, setTradein] = React.useState(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setNext({ disabled: true });
    setPrev({ disabled: false });
  }, [setNext, setPrev]);

  const onFieldsChange = () => {};

  const onSameClient = (e) => {
    setSameClient(e.target.value);
  };

  const onCredit = (e) => {
    setCredit(e.target.value);
  };
  const onTradeIn = (e) => {
    setTradein(e.target.value);
  };

  React.useEffect(() => {
    if (!_.isNull(credit) && !_.isNull(tradein)) setNext({ disabled: false });
  }, [credit, tradein, setNext]);

  const totalArray = [...carData, ...additionalData];
  const sum = _.sumBy(totalArray, "price");

  return (
    <Col span={24}>
      <Card bordered={false} className="card__main">
        <Timeline>
          <Timeline.Item>
            <Description>
              Владелец авто корректный?
              <Radio.Group
                options={[
                  { label: "Да", value: "1" },
                  { label: "Нет", value: "0" },
                ]}
                onChange={onSameClient}
                value={sameClient}
                style={{ marginLeft: 15 }}
                optionType="button"
                buttonStyle="solid"
              />
            </Description>
            <Col span={12}>
              <Divider />
            </Col>
            <Description>Заполните данные клиента</Description>
            <Col span={16}>
              <ClientForm
                onFieldsChange={onFieldsChange}
                isPhone={sameClient === "0"}
              />
            </Col>
            <Col span={12}>
              <Divider />
            </Col>
          </Timeline.Item>
          <Timeline.Item className="selected__items">
            <Col span={12}>
              <Item title="Подтвердите ранее выбранный автомобиль">
                <Button size="small" type="primary">
                  Изменить
                </Button>
              </Item>
              {carData.map(({ title, price }, index) => (
                <Item key={index} title={title}>
                  <NumberFormatter value={price} />
                </Item>
              ))}
              <Divider />
              <Item title="Товары для доукомплектации">
                <Button size="small" type="primary">
                  Изменить
                </Button>
              </Item>
              {additionalData.map(({ title, price }, index) => (
                <Item key={index} title={title}>
                  <NumberFormatter value={price} />
                </Item>
              ))}
              <Item
                extra={
                  <span style={{ marginRight: 15 }}>
                    <b>Итого:</b>
                  </span>
                }
              >
                <b>
                  <NumberFormatter value={sum} />
                </b>
              </Item>
              <Divider />
            </Col>
          </Timeline.Item>
          <Timeline.Item>
            <Description>
              Оформление авто в кредит?
              <Radio.Group
                options={[
                  { label: "Да", value: "1" },
                  { label: "Нет", value: "0" },
                ]}
                onChange={onCredit}
                value={credit}
                style={{ marginLeft: 15 }}
                optionType="button"
                buttonStyle="solid"
              />
            </Description>
            <Col span={12}>
              <Divider />
            </Col>
            {credit === "1" && (
              <>
                <Description>Предварительные расчеты по кредиту</Description>
                <Form layout="inline" style={{ marginBottom: 15 }}>
                  <Form.Item name="initalSum">
                    <FloatLabel label="Первоначальный взнос">
                      <InputNumber
                        prefix={<span>123</span>}
                        formatter={(value) =>
                          "₸ " + value.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        }
                        parser={(value) => value.replace(/[^0-9]/g, "")}
                        className="input__number"
                        placeholder="Укажите сумму"
                      />
                    </FloatLabel>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      onClick={() => {
                        const url =
                          "https://freedownloads.net/download/Account_Form_Balance_Sheet.pdf";
                        window.open(url, "_blank");
                      }}
                      type="primary"
                    >
                      Распечатать
                    </Button>
                  </Form.Item>
                </Form>
                <Col span={12}>
                  <Table
                    className="tasks__table"
                    size="small"
                    columns={creditColumns}
                    dataSource={creditData}
                    pagination={false}
                    scroll={{ y: 150 }}
                  />
                </Col>
              </>
            )}
          </Timeline.Item>
          <Timeline.Item>
            <Description>
              Приобретение через программу Trade-in?
              <Radio.Group
                options={[
                  { label: "Да", value: "1" },
                  { label: "Нет", value: "0" },
                ]}
                onChange={onTradeIn}
                value={tradein}
                style={{ marginLeft: 15 }}
                optionType="button"
                buttonStyle="solid"
              />
            </Description>
            <Col span={12}>
              <Divider />
            </Col>
          </Timeline.Item>
        </Timeline>
      </Card>
    </Col>
  );
};

const Item = ({ title, children, extra }) => (
  <div className="ant-list-item">
    <List.Item.Meta title={title} />
    <div>{extra}</div>
    {children}
  </div>
);

const NumberFormatter = ({ value }) => (
  <NumberFormat
    displayType="text"
    value={value}
    thousandSeparator=" "
    prefix="₸"
  />
);

export default Request;
